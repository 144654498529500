"use client"

import * as React from "react"
import { MapPin, X } from "lucide-react"
import { Button } from "@/components/ui/button"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"

const locations = [
  { value: "nuwara-eliya", label: "Nuwara Eliya", country: "Sri Lanka" },
  { value: "kandy", label: "Kandy", country: "Sri Lanka" },
  { value: "galle", label: "Galle", country: "Sri Lanka" },
  { value: "ella", label: "Ella", country: "Sri Lanka" },
  { value: "colombo", label: "Colombo", country: "Sri Lanka" },
]

export function SearchLocation() {
  const [open, setOpen] = React.useState(false)
  const [value, setValue] = React.useState("")

  const clearSelection = (event: React.MouseEvent) => {
    event.stopPropagation()
    setValue("")
  }

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="flex hover:bg-transparent hover:scale-[1.02] duration-100 transition-transform justify-between items-center border-[#807e7e] border-0 bg-transparent py-6 border-r rounded-none w-[358px] max-[1095px]:w-[230px] max-[1267px]:w-[260px] max-[1315px]:w-[290px] text-left pointer-events-auto"
        >
          <span className="flex items-center gap-1 font-normal text-base max-[1315px]:text-sm pointer-events-none">
            <MapPin className="search-icons"/>
            {value
              ? locations.find((location) => location.value === value)?.label
              : "Where are you going?"}
          </span>
          {value && (
            <button
              onClick={clearSelection}
              className="ml-2 text-gray-500 hover:text-gray-700 cursor-pointer pointer-events-auto"
              style={{ width: '16px', height: '16px', background: 'transparent', border: 'none' }}
              aria-label="Clear selection"
            >
              <X />
            </button>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="shadow-lg p-0 w-full md:w-[300px]">
        <Command>
          <CommandInput placeholder="Search location..." className="px-4 h-10 font-poppins" />
          <CommandList className="font-poppins">
            <CommandEmpty>No locations found.</CommandEmpty>
            <p className="px-4 py-2 font-semibold text-gray-700 text-sm">
              Popular destinations nearby
            </p>
            <CommandGroup>
              {locations.map((location) => (
                <CommandItem
                  key={location.value}
                  value={location.value}
                  onSelect={(currentValue) => {
                    setValue(currentValue === value ? "" : currentValue)
                    setOpen(false)
                  }}
                >
                  <MapPin className="mr-2 text-gray-500" />
                  <div>
                    <p className="font-medium">{location.label}</p>
                    <p className="text-gray-500 text-xs">{location.country}</p>
                  </div>
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}