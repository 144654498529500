'use client'

import { Button } from '@/components/ui/button';
import React, { useEffect, useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import TrendingFilters from '../bottomFilters/TrendingFilters';
import { SearchLocation } from '@/components/Filters/SearchLocation';
import SearchByDate from '@/components/Filters/SearchByDate';
import SearchByGuestCount from '@/components/Filters/SearchByGuestCount';
import MobileContainer from './MobileContainer';
import { useRouter } from 'next/navigation';

const SearchContainer = () => {
    const [expanded, setExpanded] = useState(false);
    const [backgroundVisible, setBackgroundVisible] = useState(false);
    const [revealText, setRevealText] = useState(false);
    const router = useRouter();

    useEffect(() => {
        const buttonTimer = setTimeout(() => {
            setExpanded(true);
        }, 300);

        const backgroundTimer = setTimeout(() => {
            setBackgroundVisible(true);
            setRevealText(true);
        }, 700);

        return () => {
            clearTimeout(buttonTimer);
            clearTimeout(backgroundTimer);
        };
    }, []);

    return (
        <section className="relative flex flex-col justify-between items-center bg-transparent w-screen h-screen self-center-custom">
            <div className="top-[25%] absolute flex flex-col justify-center items-center w-full search-container-section">
                <h1
                    className={`hero-title  text-white ${revealText ? 'animate-reveal title-delay' : ''} title-responsive`}
                >
                    Discover Wonder Land
                </h1>
                <h3
                    className={`hero-subtitle text-white ${revealText ? 'animate-reveal subtitle-delay' : ''} sub-title-responsive`}
                >
                    Book Your Dream Stay Across the Island
                </h3>
            </div>

            <div className="top-[3%] z-10 absolute flex flex-col justify-center items-center bg-transparent w-screen h-screen">
                {backgroundVisible && (
                    <>
                        <div className="relative flex border background-expand">
                            <div className="left-5 z-2 absolute flex flex-row items-center w-[85%] font-poppins">
                                <div>
                                    <SearchLocation />
                                </div>

                                <div>
                                    <SearchByDate />
                                </div>

                                <div>
                                    <SearchByGuestCount />
                                </div>
                            </div>
                        </div>
                    </>
                )}

                <div
                    className={`relative h-[52px] ${expanded ? 'w-[147px] max-[1095px]:w-[130px]' : 'w-[52px]'} rounded-[100px] border-[5px] border-[#B5C0C4] flex items-center justify-center transition-all duration-200 ${backgroundVisible ? 'search-button' : ''} ${expanded ? 'animate-expand' : 'animate-spin-scale'}`}
                >
                    <Button className="z-20 flex justify-center items-center bg-secondary rounded-[84px] w-full h-full text-center transition-all duration-300 cursor-pointer" onClick={() => router.push('/feature/filter-page')}>
                        <FaSearch className="text-white" />
                        {expanded && <span className="search-text-name max-[1095px]:text-sm">Search</span>}
                    </Button>
                </div>
            </div>

            <div className='top-[40%] z-20 absolute max-[970px]:flex flex-col justify-center items-center hidden w-full'>
                <MobileContainer />
            </div>

            <TrendingFilters />
        </section>
    );
};

export default SearchContainer;
