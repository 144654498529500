"use client";
import { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import AboutPage from "@/components/about/AboutPage";
import ImageContainer from "@/components/hero/ImageContainer/ImageContainer";
import ExplorePage from "@/components/explore/ExplorePage";
import PopularLocation from "@/components/PopularLocations/PopularLocation";
import Footer from "@/components/footer/Footer";
import { FaArrowUp } from "react-icons/fa";

gsap.registerPlugin(ScrollTrigger);

export default function Home() {
  const [showScrollTopButton, setShowScrollTopButton] = useState(false);
  const scrollListenerRef = useRef<(() => void) | null>(null);
  const aboutRef = useRef<HTMLDivElement>(null);
  const footerRef = useRef<HTMLDivElement>(null);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;

      if (scrollPosition > 300 && !showScrollTopButton) {
        setShowScrollTopButton(true);
      } else if (scrollPosition <= 300 && showScrollTopButton) {
        setShowScrollTopButton(false);
      }
    };

    const throttledScrollHandler = () => {
      if (!scrollListenerRef.current) {
        scrollListenerRef.current = () => {
          handleScroll();
          scrollListenerRef.current = null;
        };
        requestAnimationFrame(scrollListenerRef.current);
      }
    };

    window.addEventListener("scroll", throttledScrollHandler);

    return () => {
      window.removeEventListener("scroll", throttledScrollHandler);
    };
  }, [showScrollTopButton]);

  useEffect(() => {
    ScrollTrigger.matchMedia({

      "(min-width: 769px)": () => {

        gsap.fromTo(
          ".image-container",
          { opacity: 1, yPercent: 0 },
          {
            opacity: 0,
            yPercent: 0,
            ease: "power2.out",
            scrollTrigger: {
              trigger: ".image-container",
              start: "top top",
              end: "bottom center",
              scrub: true,
              onUpdate: (self) => {
                const imageContainer = document.querySelector('.image-container') as HTMLElement | null;
                if (imageContainer) {
                  if (self.progress > 0.3) {
                    imageContainer.style.pointerEvents = 'none';
                  } else {
                    imageContainer.style.pointerEvents = 'auto';
                  }
                }

              },
            },
          }
        );

        // gsap.fromTo(
        //   ".scroll-explore",
        //   { opacity: 1, yPercent: 0 },
        //   {
        //     opacity: 0,
        //     yPercent: 0,
        //     ease: "power2.out",
        //     scrollTrigger: {
        //       trigger: ".scroll-explore",
        //       start: "top top", 
        //       end: "bottom center", 
        //       pin: true,
        //       scrub: true,
        //       onUpdate: (self) => {
        //         const exploreContainer = document.querySelector('.scroll-explore') as HTMLElement | null;
        //         if (exploreContainer) {

        //           if (self.progress > 0.2) {
        //             exploreContainer.style.pointerEvents = 'none';
        //           } else {
        //             exploreContainer.style.pointerEvents = 'auto';
        //           }
        //         }
        //       },
        //     },
        //   }
        // );


        // gsap.fromTo(
        //   ".scroll-explore",
        //   { scale: 1, yPercent: 0 },
        //   {
        //     scale: 0.9, 
        //     yPercent: 20, 
        //     ease: "power2.out",
        //     scrollTrigger: {
        //       trigger: ".scroll-explore",
        //       start: "top top",
        //       end: "bottom center",
        //       pin: true,
        //       scrub: true,
        //       onUpdate: (self) => {
        //         const exploreContainer = document.querySelector('.scroll-explore') as HTMLElement | null;
        //         if (exploreContainer) {
        //           if (self.progress > 0.2) {
        //             exploreContainer.style.pointerEvents = 'none';
        //           } else {
        //             exploreContainer.style.pointerEvents = 'auto';
        //           }
        //         }
        //       },
        //     },
        //   }
        // );

        // gsap.fromTo(
        //   ".scroll-explore",
        //   { opacity: 1, yPercent: 0 },
        //   {
        //     opacity: 0.6,
        //     yPercent: 0,
        //     ease: "power2.out",
        //     scrollTrigger: {
        //       trigger: ".scroll-explore",
        //       start: "top top",
        //       end: "bottom center",
        //       pin: true,
        //       scrub: true,
        //       onUpdate: (self) => {
        //         const exploreContainer = document.querySelector('.scroll-explore') as HTMLElement | null;
        //         if (exploreContainer) {

        //           if (self.progress > 0.4) {
        //             exploreContainer.style.pointerEvents = 'none';
        //           } else {
        //             exploreContainer.style.pointerEvents = 'auto';
        //           }
        //         }
        //       },
        //     },
        //   }
        // );


        gsap.fromTo(
          ".about-page",
          { y: 0 },
          {
            y: "0",
            ease: "power2.out",
            scrollTrigger: {
              trigger: ".about-page",
              start: "top bottom",
              end: "top top",
              scrub: true,
            },
          }
        );

        gsap.fromTo(
          ".navbar-container-main",
          { opacity: 1, yPercent: 0 },
          {
            opacity: 0,
            yPercent: -20,
            ease: "power2.out",
            scrollTrigger: {
              trigger: ".about-page",
              start: "top bottom",
              end: "top center",
              scrub: true,
              onEnter: () => {
                const navbarChildren = document.querySelectorAll('.navbar-container-main > *') as NodeListOf<HTMLElement>;
                navbarChildren.forEach((child) => {
                  child.style.pointerEvents = 'none';
                });
              },
              onLeaveBack: () => {
                const navbarChildren = document.querySelectorAll('.navbar-container-main > *') as NodeListOf<HTMLElement>;
                navbarChildren.forEach((child) => {
                  child.style.pointerEvents = 'auto';
                });
              },
            },
          }
        );

      },

      "(max-width: 768px)": () => {
        ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
      },
    });


    return () => {
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, []);

  return (
    <>
      <main className="w-full bg-primary">
        <section className="image-container h-screen">
          <ImageContainer aboutRef={aboutRef} footerRef={footerRef} />
        </section>

        <section className="about-page bg-white w-full" ref={aboutRef}>
          <AboutPage />
        </section>

        <section className="max-[769px]:relative bg-primary h-screen panel scroll-explore">
          <ExplorePage />
        </section>

        <section className="max-[769px]:relative top-0 z-40 sticky bg-white w-full min-h-screen">
          <PopularLocation />
        </section>

        {showScrollTopButton && (
          <button
            onClick={scrollToTop}
            className="fixed bottom-8 right-8 border bg-primary text-white z-[99999] rounded-full p-4 shadow-lg transition-transform duration-300 transform hover:scale-110 focus:outline-none"
            aria-label="Scroll to top"
            style={{ opacity: showScrollTopButton ? 1 : 0 }}
          >
            <FaArrowUp size={18} />
          </button>
        )}
      </main>

      <Footer footerRef={footerRef} />

    </>
  );
}
