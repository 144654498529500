import { Button } from '@/components/ui/button'
import Image from 'next/image';
import { useRouter } from 'next/navigation'
import React from 'react'
import defaultIcon from '@/public/shared/Logo-white.png'

const TrendingFilterButton = ({ icon, label, href, className = '', style = {} }: { icon: string, label: string, href: string, className?: string, style?: React.CSSProperties }) => {
    const router = useRouter();

    return (
        <Button
            className={`flex flex-col items-center justify-center text-center gap-2 max-[426px]:gap-1 bg-transparent text-white font-poppins max-w-24 ${className} hover:bg-transparent custom-trending-filter-item`}
            onClick={() => router.push('/feature/filter-page')
                
            }
            style={style}
        >
            <Image src={icon || defaultIcon} alt={label} width={28} height={28} className="max-[426px]:!text-md" />
            <h3 className="font-normal text-sm max-[426px]:text-[10px] max-[916px]:text-[10px] max-[970px]:text-xs">{label}</h3>
        </Button>
    )
}

export default TrendingFilterButton
