"use client";

import React, { useState } from "react";
import { Star, Heart } from "lucide-react";
import { Button } from "../ui/button";
import { useRouter } from "next/navigation";
import { Rating } from "@/common/types";
import { GoArrowUpRight } from "react-icons/go";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip"
import { toast } from "@/hooks/use-toast";
import defaultLocation from '@/public/shared/DefaultLocation.png'
import defaultLocationHover from '@/public/shared/DefaultLocationHover.png'


const DealCard = ({
  option1,
  option2,
  name,
  id,
  address,
  favorite,
  route,
  rating,
}: {
  option1: string;
  id: number,
  option2: string;
  name: string;
  address: string;
  favorite: boolean;
  route: string;
  rating: Rating;
}) => {
  const router = useRouter();

  const handleNavigate = (route: string) => {
    // router.push(route);
    router.push('/feature/filter-page');
  };
  const [isFavorite, setIsFavorite] = useState(favorite);

  const toggleFavorite = () => {
    setIsFavorite((prev) => !prev);
    toast({
      description: !isFavorite ? "Added to wishlist" : "Removed from wishlist",
      className: "bg-primary font-poppins text-white p-4 rounded-lg shadow-md",
      duration: 1000,
    });

  };


  const patternOptionId = `bgPatternOption-${id}`;
  const patternHoverOptionId = `bgPatternOptionHover-${id}`;
  return (
    <div className="relative m-5 sm:mx-10 w-max h-max deal-container">
      <svg
        width="290"
        height="324"
        viewBox="0 0 290 324"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="max-[430px]:w-[233px] max-[900px]:w-[274px] h-auto deal-svg"
        onClick={() => {
          handleNavigate(route);
        }}
      >
        <defs>
          <pattern id={patternOptionId} patternUnits="objectBoundingBox" width="1" height="1">
            <image
              href={option1 || defaultLocation.src}
              width="100%"
              height="100%"
              preserveAspectRatio="xMidYMid slice"
              className="image-primary"
            />
          </pattern>
          <pattern id={patternHoverOptionId} patternUnits="objectBoundingBox" width="1" height="1">
            <image
              href={option2 || defaultLocationHover.src}
              width="100%"
              height="100%"
              preserveAspectRatio="xMidYMid slice"
              className="image-hover"
            />
          </pattern>
          <linearGradient id="textBackground" x1="0" y1="1" x2="0" y2="0">
            <stop offset="0%" stopColor="#0B161F" />
            <stop offset="100%" stopColor="transparent" />
          </linearGradient>
          <clipPath id="clipPath">
            <path
              d="M289.985 42.0202V225.62C289.985 239.143 279.534 249.943 265.358 249.943C264.449 249.943 263.449 249.943 262.45 249.943C242.275 250.76 225.917 267.277 225.19 287.425C225.19 288.696 225.281 289.876 225.281 291.146C225.281 309.751 212.468 323.637 194.838 323.637H42.0755C18.9022 323.637 0 304.85 0 281.617V42.0202C0 18.8773 18.8113 0 42.0755 0H247.909C271.174 0 289.985 18.7866 289.985 42.0202Z"
            />
          </clipPath>
        </defs>
        <g clipPath="url(#clipPath)">
          <path
            d="M289.985 42.0202V225.62C289.985 239.143 279.534 249.943 265.358 249.943C264.449 249.943 263.449 249.943 262.45 249.943C242.275 250.76 225.917 267.277 225.19 287.425C225.19 288.696 225.281 289.876 225.281 291.146C225.281 309.751 212.468 323.637 194.838 323.637H42.0755C18.9022 323.637 0 304.85 0 281.617V42.0202C0 18.8773 18.8113 0 42.0755 0H247.909C271.174 0 289.985 18.7866 289.985 42.0202Z"
            fill={`url(#${patternOptionId})`}
            className="svg-path"
          />
          <path
            d="M289.985 42.0202V225.62C289.985 239.143 279.534 249.943 265.358 249.943C264.449 249.943 263.449 249.943 262.45 249.943C242.275 250.76 225.917 267.277 225.19 287.425C225.19 288.696 225.281 289.876 225.281 291.146C225.281 309.751 212.468 323.637 194.838 323.637H42.0755C18.9022 323.637 0 304.85 0 281.617V42.0202C0 18.8773 18.8113 0 42.0755 0H247.909C271.174 0 289.985 18.7866 289.985 42.0202Z"
            fill={`url(#${patternHoverOptionId})`}
            className="svg-hover-path"
          />
          <rect x="0" y="240" width="290" height="84" fill="url(#textBackground)" />
        </g>

        <text
          x="20"
          y={
            address.length > 26 && name.length > 15 ?
              "240" :
              name.length > 26 && address.length <= 26 ?
                "235"
                : name.length > 15 && address.length <= 26 ?
                  "258"
                  : name.length <= 15 && address.length > 26 ?
                    "258" :
                    "280"
          }
          fill="white"
          className="svg-text-title"
        >
          {(() => {
            const maxLength = 15;
            const words = name.split(' ');
            const lines: string[] = [];
            let currentLine = '';

            words.forEach((word) => {
              if ((currentLine + word).length <= maxLength) {
                currentLine += (currentLine ? ' ' : '') + word;
              } else {
                lines.push(currentLine);
                currentLine = word;
              }
            });

            if (currentLine) lines.push(currentLine);

            return lines.map((line, index) => (
              <tspan key={index} x="20" dy={index === 0 ? 0 : 22}>
                {line}
              </tspan>
            ));
          })()}
        </text>
        <text
          x="20"
          y={address.length > 26 ? "278" : "300"}
          fontSize="14"
          fill="white"
          className="svg-text-subtitle"
        >
          {(() => {
            const maxLength = 26;
            const words = address.split(' ');
            const lines: string[] = [];
            let currentLine = '';

            words.forEach((word) => {
              if ((currentLine + word).length <= maxLength) {
                currentLine += (currentLine ? ' ' : '') + word;
              } else {
                lines.push(currentLine);
                currentLine = word;
              }
            });

            if (currentLine) lines.push(currentLine);

            return lines.map((line, index) => (
              <tspan key={index} x="20" dy={index === 0 ? 0 : 18}>
                {line}
              </tspan>
            ));
          })()}
        </text>
        <g transform="translate(20, 305)" className="svg-text-rating-container">
          <foreignObject x="20" y="295" width="100%" height="100%">
            <div className="flex items-center space-x-2">
              {
                rating > 0 ? (
                  <>
                    <span className="svg-text-rating text-sm text-white">Rating</span>
                    {Array.from({ length: rating }).map((_, index) => (
                      <Star key={index} size={14} color="white" className="fill-white svg-rating-star" />
                    ))}
                  </>
                ) : (
                  <span className="svg-text-rating text-sm text-white">Not rated yet</span>
                )
              }
            </div>
          </foreignObject>
        </g>
      </svg>
      <TooltipProvider delayDuration={200}>
        <Tooltip>
          <TooltipTrigger asChild>
            <button
              onClick={toggleFavorite}
              className={`wishlist-btn absolute flex items-center justify-center rounded-full transition-colors ${isFavorite ? "bg-secondary text-white" : "bg-gray-100 text-gray-500"
                }`}
              aria-label="Add to favorites"
            >
              <Heart size={24} color={isFavorite ? "white" : "currentColor"} fill={isFavorite ? "#EF5A60" : "none"} className="max-[769px]:w-5 max-[769px]:h-5" />
            </button>
          </TooltipTrigger>
          <TooltipContent side="bottom" align="center">
            <p className="font-poppins">
              {isFavorite ? 'remove from wishlist' : 'add to wishlist'}
            </p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>


      <TooltipProvider delayDuration={200} >
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              onClick={() => {
                handleNavigate(route);
              }}
              className="flex justify-center items-center goto-deals-details-btn p-0 rounded-full hover:scale-110 duration-300"
              aria-label="Move up right"
              role="button"
            >
              <GoArrowUpRight size={48} className="flex-shrink-0 !text-xl text-current" />
            </Button>
          </TooltipTrigger>
          <TooltipContent side="bottom" align="center">
            <p className="font-poppins">Book {name}</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>



    </div>
  );
};

export default DealCard;