import React from 'react'
import Sigiriya from "@/public/hero-images/sigiriya.png"
import SearchContainer from '../serachContainer/SearchContainer'
import Navigation from '@/components/navigation/Navigation'

const ImageContainer: React.FC<{ aboutRef?: React.RefObject<HTMLDivElement>, footerRef?: React.RefObject<HTMLDivElement> }> = ({ aboutRef, footerRef }) => {
    return (
        <>
            <div className='max-[769px]:relative top-0 sticky w-full h-screen'>
                <div
                    className="relative z-[-1] bg-cover bg-center w-full min-h-screen"
                    style={{ backgroundImage: `url(${Sigiriya.src})` }}
                >
                    <div className="absolute inset-0 bg-gradient-to-t from-[#0B161F] via-[transparent] to-transparent opacity-60"></div>

                </div>
                <SearchContainer />
                <div className='bottom-10 z-20 fixed min-[770px]:flex flex-col justify-center items-center hidden w-full'>
                    <Navigation aboutRef={aboutRef} footerRef={footerRef}/>
                </div>
            </div>
        </>

    )
}

export default ImageContainer