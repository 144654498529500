import { ellaLocations, galleLocations, popularLocations, weeklyDealsOptions, yapahuwaLocations } from '@/helpers/mockArrays';
import React, { useEffect, useState } from 'react';
import LocationCard from './LocationCard';
import { Locations } from '@/common/interfaces';
import {
    Carousel,
    CarouselContent,
    CarouselItem,
    CarouselNext,
    CarouselPrevious,
} from "@/components/ui/carousel"
import Galle from '@/public/discover/Galle.webp'
import Kandy from '@/public/discover/Kandy.webp'
import Gampola from '@/public/discover/Gampola.webp'
import Colombo from '@/public/discover/Colombo.webp'


const LocationsDetails = ({ updateLocationDetails, locationData }: {
    updateLocationDetails: (description: string, title: string | undefined, locations: Locations[], reviewers?: any, properties?: any) => void,
    locationData: any
}) => {


    const [title, setTitle] = useState<string | undefined>('');
    const [selectedLocationId, setSelectedLocationId] = useState<number | string>(1);
    const [locations, setLocations] = useState<any>([]);
    const [data, setData] = useState<any[]>([]);
    const [reviewers, setReviewers] = useState([]);

    function setDescription(paragraph: string | undefined, id: number | string, description: string, name: string, reviewers?: any, properties?: any) {
        setTitle(paragraph);
        setSelectedLocationId(name + 'ServicedApartments');

        let updatedLocations: any = [];

        switch (name) {
            case 'Colombo':
                updatedLocations = galleLocations;
                break;
            case 'Gampola':
                updatedLocations = ellaLocations;
                break;
            case 'Yapahuwa':
                updatedLocations = yapahuwaLocations;
                break;
            default:
                updatedLocations = weeklyDealsOptions;
                break;
        }

        setLocations(updatedLocations);
        updateLocationDetails(description, name, updatedLocations, reviewers, properties);
    }

    const highlightLocationName = (description: string, locationName: string) => {
        const regex = new RegExp(`(${locationName})`, 'gi');
        return description.split(regex).map((part, index) => {
            return part.toLowerCase() === locationName.toLowerCase() ? (
                <span key={index} className="text-secondary">{part}</span>
            ) : (
                part
            );
        });
    };

    useEffect(() =>{
        if (data && data.length > 0) {
            setSelectedLocationId(data[0].city + 'ServicedApartments');
        }
    },[data])

    useEffect(() => {
        setData(locationData);
    }, [locationData])


    return (
        <>
            <div className='w-full'>
                <div className="flex flex-row max-[769px]:hidden">
                    {
                        data?.map((location: any) => (
                            <LocationCard
                                name={location.city}
                                href={location.city === "Colombo" ? Colombo : location.city === "Gampola" ? Gampola : location.city === "Galle" ? Galle : location.city === "Kandy" ? Kandy : Colombo}
                                alt={`${location.city || 'undefined'}, SriLanka`}
                                key={location.city + 'ServicedApartments'}
                                onClick={() => setDescription(
                                    location.city === 'Colombo' ? "Vibrant Culture in Colombo's Streets" : location.city === 'Galle' ? "Uncover Galle's Coastal Beauty" : location.city === 'Gampola' ? "Unveil the Beauty of Gampola's Highlands" : location.city === 'Kandy' ? "Explore Kandy's Rich Heritage" : "Uncover Galle's Coastal Beauty",
                                    location.city + 'ServicedApartments',
                                    location.description || `Are you seeking an advanced solution to manage your property across multiple platforms, or are you interested in partnering with us for full-service management?
Serviced Apartments LK has partnered with the top online property agencies across the world, to connect with millions of guests who are looking for a property just like yours.`,
                                    location.city,
                                    location.reviewers,
                                    location.properties,
                                )}
                                isSelected={selectedLocationId === location.city + 'ServicedApartments'}
                            />
                        ))
                    }

                </div>

                <div className='max-[769px]:block hidden px-10'>
                    <Carousel>
                        <CarouselContent>
                            {popularLocations.map((location) => (
                                <CarouselItem className='max-[769px]:basis-1/2 max-[426px]:basis-full shrink-0' key={location.id}>
                                    <LocationCard
                                        name={location.name}
                                        href={location.href}
                                        alt={location.alt}
                                        key={location.id}
                                        onClick={() => setDescription(location.description, location.id, location.paragraph, location.name)}
                                        isSelected={selectedLocationId === location.id}
                                    />
                                </CarouselItem>

                            ))}
                        </CarouselContent>
                        <CarouselPrevious />
                        <CarouselNext />
                    </Carousel>
                </div>
            </div>

            <div>
                <h1 className='mt-5 w-[85%] max-[1000px]:w-full description-title-large'>
                    {title
                        ?
                        highlightLocationName(
                            title,
                            popularLocations.find(location => title.includes(location.name))?.name || ''
                        )
                        : (
                            <>
                                {"Uncover "}
                                <span className="text-secondary">Galle</span>
                                {"'s Coastal Beauty"}
                            </>
                        )
                        }
                </h1>
            </div>
        </>
    );
};

export default LocationsDetails;