'use client'
import DealCard from "./DealCard";
import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from "@/components/ui/carousel";
import { Rating } from "@/common/types";
import { useEffect, useRef, useState } from "react";
import { DealsProps } from "@/common/interfaces";

const DealsList = ({ dealsList }: { dealsList: DealsProps[] }) => {
  const [deals, setDeals] = useState<DealsProps[]>([]);

  useEffect(() => {
    setDeals(dealsList)
  }, [dealsList]);

  const aboutContainerStaysContainerRef = useRef<HTMLDivElement | null>(null);
  const aboutContainerStaysContainerChildRef = useRef<HTMLDivElement | null>(null);

  const animationsTriggered = useRef(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !animationsTriggered.current) {
          animationsTriggered.current = true;
          if (aboutContainerStaysContainerRef.current) {
            if (entry.isIntersecting) {
              aboutContainerStaysContainerRef.current.classList.add('animatedSlideLeftDelayed');
            } else {
              aboutContainerStaysContainerRef.current.classList.remove('animatedSlideLeftDelayed');
            }
          }
          if (aboutContainerStaysContainerChildRef.current) {
            if (entry.isIntersecting) {
              aboutContainerStaysContainerChildRef.current.classList.add('animatedChildSlideUpDelayed');
            } else {
              aboutContainerStaysContainerChildRef.current.classList.remove('animatedChildSlideUpDelayed');
            }
          }

        }


      },
      {
        root: null,
        threshold: 0.1,
      }
    );

    if (aboutContainerStaysContainerRef.current) {
      observer.observe(aboutContainerStaysContainerRef.current);
    }

    return () => {
      if (aboutContainerStaysContainerRef.current) {
        observer.unobserve(aboutContainerStaysContainerRef.current);
      }
    };
  }, []);



  return (
    <>
      {deals ?(
        <div className="relative flex flex-col items-center opacity-0 max-[426px]:m-0 mt-10 w-full" ref={aboutContainerStaysContainerRef}>
        <Carousel className="relative w-full">
          <CarouselContent>
            {deals.map((deal) => (
              <CarouselItem
                key={deal.id}
                className={`
              m-0
              p-0
              max-[290px]:!basis-full 
              max-[321px]:!basis-[88%] 
              max-[365px]:!basis-[80%] 
              max-[500px]:!basis-[70%] 
              max-[610px]:!basis-[65%] 
              max-[769px]:!basis-[55%] 
              max-[850px]:!basis-[45%] 
              max-[980px]:!basis-[40%] 
              min-[980px]:!basis-[40%] 
              min-[1535px]:!basis-[25%] 
              min-[1720px]:!basis-1/5 
              min-[2000px]:!basis-[18%]
              lg:!basis-1/3 
              xl:!basis-1/4 
              flex-shrink-0 
              transition-transform ease-out duration-300
            `}
              >

                <div className="p-1">
                  <DealCard
                    option1={deal.images.find((img:any) => img.isCover)?.file?.mediumPath || '' }
                    id={deal.id}
                    option2={deal.images.find((img:any) => !img.isCover)?.file?.mediumPath || ''}
                    name={deal.name}
                    address={`${deal.location?.city?.name || 'Undefined'}, ${deal.location?.province?.name || 'Undefined'}`}
                    favorite={false}
                    route={`/apartments/${deal.id}`}
                    rating={deal.summaryReview.averageReviews as Rating}
                  />
                </div>
              </CarouselItem>
            ))}
          </CarouselContent>


          <div className="-top-20 max-[495px]:!top-0 right-20 max-[527px]:!right-10 md:right-14 absolute flex gap-8 max-[769px]:hidden opacity-0 w-2" ref={aboutContainerStaysContainerChildRef}>
            <CarouselPrevious className="bg-primary p-6 max-[425px]:p-5 rounded-xl max-[425px]:rounded-lg text-white" />
            <CarouselNext className="bg-primary p-6 max-[425px]:p-5 rounded-xl max-[425px]:rounded-lg text-white" />
          </div>
        </Carousel>
      </div>
      ):(
        <div>Loading....</div>
      )}
    </>
  );
};

export default DealsList;
