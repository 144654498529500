export const fetchAboutData = async () => {
  try {
    const res = await fetch('/api/about');
    if (!res.ok) {
      console.error(`HTTP error! Status: ${res.status}`);
      return [];
    }

    const data = await res.json();

    if (res.ok) {
      return data.data;
    }

  } catch (error: any) {
    console.error('Fetch error:', error.message);
    return [];
  }
};