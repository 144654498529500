import React, { useEffect, useState } from 'react'
import Deals from './Deals'
import { getUpcomingWeekend } from '@/common/commonFunctions';
import { loadInitialDiscounts } from '@/actions/services/getDeals';

const WeekendDeals = () => {
    const [date, setDate] = useState<{ formattedSaturday: string; formattedSunday: string } | null>(null);
    const [discounts, setDiscounts] = useState<any[]>([])
    const [discountsRecent, setDiscountsRecent] = useState([])

    useEffect(() => {
        const loadDate = async () => {
            const date = getUpcomingWeekend();
            setDate(await date);
        };
        loadDate();
        loadDiscounts();
    }, []);



    const loadDiscounts = async () => {
        setDiscounts([]);
        setDiscountsRecent([]);

        const response = await loadInitialDiscounts();

        const data = response.data;

        if (data.length > 10) {
            const middleIndex = Math.ceil(data.length / 2);

            setDiscountsRecent(data.slice(0, middleIndex));
            setDiscounts(data.slice(middleIndex));

        } else {
            setDiscountsRecent(data);
            setDiscounts([]);
        }
    }

    return (
        <>
            <div className={`mt-20 pb-20 w-full h-max ${discountsRecent.length === 0 ? 'hidden' : 'block'}`}>
                <div className='w-screen'>
                    <div className='px-20 max-[769px]:px-5'>
                        <h1 className="title">Deals for the weekend</h1>
                        <h3 className="subtitle">
                            {date
                                ? `Save on stays for ${date.formattedSaturday} - ${date.formattedSunday}`
                                : 'Loading...'}
                        </h3>
                    </div>

                    <div className='max-[769px]:m-0 mt-10'>
                        <Deals discountList={discounts} discountRecent={discountsRecent} />
                    </div>
                </div>
            </div>
            <div className={`${discountsRecent.length === 0 ? 'mt-20' : 'mt-0'}`}>

            </div>
        </>
    )
}

export default WeekendDeals