"use client";
import React, { useEffect, useRef, useState } from "react";
import Link from "next/link";
import { motion } from "framer-motion";
import { CursorProps, Position, TabProps } from "@/common/interfaces";
import { navOptions } from "./NavigationOptions";
import { useRouter } from "next/navigation";

const Navigation: React.FC<{ aboutRef?: React.RefObject<HTMLDivElement>, footerRef?: React.RefObject<HTMLDivElement> }> = ({ aboutRef, footerRef }) =>  {
  const [revealText, setRevealText] = useState(false);
  const [position, setPosition] = useState<Position>({
    left: 0,
    width: 0,
    opacity: 0,
  });
  const [activeIndex, setActiveIndex] = useState<number | null>(0);
 const router = useRouter();

  useEffect(() => {
    const textTimer = setTimeout(() => {
      setRevealText(true);
    }, 1200);

    return () => {
      clearTimeout(textTimer);
    };
  }, []);

  const handleAboutUsClick = (event: React.MouseEvent) => {
    event.preventDefault(); // Prevent navigation if using `Link`
    if (aboutRef?.current) {
      const elementPosition = aboutRef.current.getBoundingClientRect().top + window.scrollY;
      const offset = 0; // Adjust if necessary for sticky headers
  
      window.scrollTo({
        top: elementPosition - offset,
        behavior: "smooth",
      });
    }
  };

  const handleContactClick = (event: React.MouseEvent) => {
    event.preventDefault(); 
    if (footerRef?.current) {
      const elementPosition = footerRef.current.getBoundingClientRect().top + window.scrollY;
      const offset = 0; 
  
      window.scrollTo({
        top: elementPosition - offset,
        behavior: "smooth",
      });
    }
  };


  const handleTravelsClick = (event: React.MouseEvent) => {
   router.push("/feature/travels-page");
  };


  const handleBookingsClick = (event: React.MouseEvent) => {
   router.push("/feature/bookings-page");
  };


  return (
    <div className={`border w-max h-max p-1 bg-[rgba(255,255,255,0.1)] rounded-full border-[rgba(255,255,255,0.3)] opacity-0 ${revealText ? "custom-navigation-bar" : ""}`}>
      <ul
        onMouseLeave={() => {
          setPosition((prev) => ({
            ...prev,
            opacity: 0,
          }));
        }}
        className="relative mx-auto flex w-fit rounded-full p-1 custom-navigation-bar-wrapper"
      >
        {navOptions.map((option, index) => (
          <Link
            href={option.href}
            key={index}
            onClick={option.label === "About Us" ? handleAboutUsClick:
              option.label === "Contact" ? handleContactClick  :
              option.label === "Travels" ? handleTravelsClick  : 
              option.label === "Bookings" ? handleBookingsClick  : undefined

            }
          >
            <Tab
              setPosition={setPosition}
              setActiveIndex={() => setActiveIndex(index)}
              isActive={index === activeIndex}
            >
              {option.label}
            </Tab>
          </Link>
        ))}
        <Cursor position={position} />
      </ul>
    </div>
  );
};

const Tab: React.FC<TabProps & { isActive: boolean; setActiveIndex: () => void }> = ({
  children,
  setPosition,
  setActiveIndex,
  isActive,
}) => {
  const ref = useRef<HTMLLIElement>(null);

  return (
    <motion.li
      ref={ref}
      onMouseEnter={() => {
        if (!ref.current) return;

        const { width } = ref.current.getBoundingClientRect();

        setPosition({
          left: ref.current.offsetLeft,
          width,
          opacity: 1,
        });
      }}
      onClick={() => {
        setActiveIndex();
      }}
      animate={{
        backgroundColor: isActive ? "#EF5A60" : "transparent",
        color: isActive ? "#FFFFFF" : "#000000",
      }}
      whileHover={{
        color: "#FFFFFF",
      }}
      transition={{
        type: "spring",
        stiffness: 300,
        damping: 25,
      }}
      className="relative z-10 block cursor-pointer px-2 py-1 text-xs md:px-4 md:py-2 md:text-sm font-normal rounded-full font-poppins"
    >
      {children}
    </motion.li>
  );
};

const Cursor: React.FC<CursorProps> = ({ position }) => (
  <motion.li
    animate={{
      left: position.left,
      width: position.width,
      opacity: position.opacity,
    }}
    transition={{
      type: "spring",
      stiffness: 300,
      damping: 25,
    }}
    className="absolute z-0 h-5 rounded-full bg-secondary md:h-9"
  />
);

export default Navigation;
