import React, { useEffect, useRef } from 'react';
import AboutButton from '../common/about/AboutButton';
import '@/styles/earn-more.css'
import { useRouter } from 'next/navigation';

const EarnMore = () => {
  const earnMoreRef = useRef<HTMLDivElement | null>(null);
  const router = useRouter();


  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {


        if (earnMoreRef.current) {
          if (entry.isIntersecting) {
            earnMoreRef.current.classList.add('animatedGradient');
          } else {
            earnMoreRef.current.classList.remove('animatedGradient');
          }
        }
      },
      {
        root: null,
        threshold: 0.1,
      }
    );

    if (earnMoreRef.current) {
      observer.observe(earnMoreRef.current);
    }

    return () => {
      if (earnMoreRef.current) {
        observer.unobserve(earnMoreRef.current);
      }
    };
  }, []);

  const goToHost = () => {
    router.push('/feature/services-page')
    // const hostUrl =  process.env.HOST_URL || 'https://apartment-host.webmotech.com';
    // window.open(hostUrl, '_blank');
  }

  return (
    <div className="relative flex flex-row max-[1000px]:flex-col justify-between items-center bg-gradient-to-r from-[#3976A6] via-[#012B4C] to-[#012B4C] px-20 max-[1440px]:px-10 max-[435px]:px-3 max-[672px]:px-5 py-24 max-[1745px]:py-20 p-10 w-full h-max text-white overflow-hidden" ref={earnMoreRef}>
      <h1 className="w-1/2 max-[1000px]:w-full max-[1000px]:text-center tracking-wide description-title-large">
      Simplify Your Journey with Serviced Apartment
      </h1>
      <div className="p-16 w-1/2 max-[1000px]:w-full max-[1000px]:p-0 self-start">
        <p className="mb-12 w-full description-paragraph-default leading-relaxed tracking-wide max-[1000px]:mt-10 max-[1000px]:text-center">
          Experience hassle-free travel with modern amenities, flexible bookings, and personalized service, all designed to make your stay seamless and comfortable.
        </p>

        <AboutButton label={'Explore Our Services'} type={'host'} onClick={goToHost} />

      </div>
    </div>
  );
};

export default EarnMore;
