export const navOptions = [
    {
        label: "Home",
        href: ''
    },
    {
        label: "About Us",
        href: ''
    },
    {
        label: "Contact",
        href: ''
    },
    {
        label: "Travels",
        href: ''
    },
    {
        label: "Bookings",
        href: ''
    },
]